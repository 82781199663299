.community-section {
  margin-bottom: 20px;
  position: relative;
  .community-container {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    a {
      color: #dba514;
      padding: 15px;
      border-radius: 50%;
      border: 3px solid #dba514;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      transition: all 0.4s;
      &:hover{
        color: black;
        border:3px solid black;
        background-color: #dba514;
      }
    }
  }
}
