.header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 20px;
    width: calc(100% - 20px);
    @media screen and (max-width: 1396px) {
      display: none;
    }
    .logo-container{
      position: relative;
      z-index: 40;
    }
    .logo {
      width: 100px;
      z-index: 3;
      height: 100px;
      border-radius: 50px;
    }
    .nav-bar-container {
      background-color: #000000;
      width: 100%;
      z-index: 2;
      transform: translateX(-10px);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      ul {
        display: flex;
        justify-content: space-around;
      }
      ul li {
        list-style: none;
        a {
          font-family: "Avenir-Light";
          text-decoration: none;
          color: #dba514;
          text-transform: uppercase;
          font-size: 18px;
          white-space: nowrap;
        }
      }
    }
  }
  .mobile-header {
    display: none;
    @media screen and (max-width: 1396px) {
      display: flex;
      position: fixed;
      width: calc(100%);
      margin-left: -20px;
      margin-top: -20px;
      padding: 10px 20px;
      background-color: #00000080;
      z-index: 30;
      .mobile-logo {
        width: 60px;
        height: 60px;
      }
      .menu-trigger {
        transition: all 0.4s;
        background-color: #1e1e1e;
        display: block;
        position: absolute;
        width: 30px;
        height: 2px;
        right: 20px;
        top: 40px;
        &::before {
          content: "";
          transform-origin: 33% 100%;
          top: -10px;
          z-index: 10;
          transition: all 0.4s;
          background-color: #1e1e1e;
          display: block;
          position: absolute;
          height: 2px;
          left: 0;
          width: 75%;
        }
        &::after {
          content: "";
          transform-origin: 33% 0;
          top: 10px;
          transition: all 0.4s;
          background-color: #1e1e1e;
          display: block;
          position: absolute;
          height: 2px;
          left: 0;
          width: 75%;
        }
      }
      .menu-trigger-close {
        transition: all 0.4s;
        background-color: transparent;
        width: 30px;
        display: block;
        position: absolute;
        height: 2px;
        right: 20px;
        top: 40px;
        &::before {
          content: "";
          transition: all 0.4s;
          transform: translateY(6px) translateX(0px) rotate(45deg);
          background-color: #1e1e1e;
          width: 30px;
          top: -10px;
          z-index: 10;
          height: 2px;
          display: block;
          position: absolute;
        }
        &::after {
          content: "";
          transition: all 0.4s;
          transform: translateY(-3px) translateX(0px) rotate(-45deg);
          background-color: #1e1e1e;
          width: 30px;
          height: 2px;
          display: block;
          position: absolute;
        }
      }
      .mobile-nav-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        position: absolute;
        right: 20px;
        top: 70px;
        background-color: #00000080;
        padding: 10px 20px;
        z-index: 20;
        list-style: none;
        border-radius: 10px;
        a {
          text-decoration: none;
          margin: 10px;
          font-size: 20px;
          text-transform: uppercase;
          color:aqua;
        }
      }
    }
  }