.team-section {
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  h1 {
    font-family: "Bungee-inline";
    font-size: 60px;
    margin-bottom: 60px;
    @media screen and (max-width: 900px) {
      margin-bottom: 10px;
    }
  }
  .team-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
}
