.faq-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #dba514;
  padding: 15px;
  background-color: black;
  margin-bottom: 13px;
  .faq-header {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .question-mark {
      text-align: center;
      color: #dba514;
      width: 30px;
      height: 30px;
    }
    p {
      margin: 0;
      margin-left: 20px;
      font-size: 24px;
      font-weight: 600;
      color: #dba514;

    }
    button {
      margin-left: auto;
      background: transparent;
      border: none;
    }
    .open-content {
      text-align: center;
      color: #dba514;
      width: 30px;
      height: 30px;
      transition: all 0.3s;
    }
    .active {
      transition: all 0.3s;
      transform: rotate(45deg);
    }
  }

  .faq-content {
    padding-left: 30px;
    text-align: left;
    transition: all, 0.4s;
    background-color: rgba(0, 0, 0, 0.678);
    p {
      color: rgb(185, 182, 182);
      font-size: 21px;
    }
  }
}
