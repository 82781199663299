.roadmap-component {
  position: relative;
  h1 {
    font-family: "Bungee-inline";
    font-size: 60px;
  }
  .vertical-timeline-element-content {
    background-color: black;
    color: white;
    box-shadow:0 3px 0 black;
    padding: 1em;
  }
  .vertical-timeline-element-content-arrow{
    border-right-color: black;
  }
}
