.collection-section {
  position: relative;
  h1 {
    font-family: "Bungee-inline";
    font-size: 60px;
  }
  .collection-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
}
