.team-component {
  border-top: 5px solid #dba514;
  border-left: 5px solid #dba514;
  border-bottom: 5px solid black;
  border-right: 5px solid black;
  border-radius: 5px;
  width: 20%;
  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 20px 0px;
  }
  img {
    width: 100%;
  }
  .member-name{
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 0;
  }
  .member-job{
      font-size: 20px;
      font-weight: 400;
  }
}
