.home-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  .home-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 7%;
    .left-content {
      width: 60%;
    }
    .right-content {
      width: 40%;
      border-radius: 50%;
      img {
        width: 80%;
        border-radius: 50%;
        border: 1px solid;
        box-shadow: 0px 0px 30px 20px black;
      }
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      .left-content {
        width: 100%;
      }
      .right-content {
        margin-top: 50px;
        width: 80%;
        border-radius: 50%;
        img {
          width: 80%;
          border-radius: 50%;
          border: 1px solid;
          box-shadow: 0px 0px 30px 20px black;
        }
      }
    }
    h1 {
      font-family: "Bungee-shade";
      font-size: 70px;
      margin-bottom: 0;
      line-height: 100px;
    }
    p {
      font-family: "Bungee-inline";
      font-size: 30px;
      margin: 5px;
      line-height: 30px;
    }
  }
}
