.faq-section {
  box-sizing: border-box;
  position: relative;
  h1 {
    font-family: "Bungee-inline";
    font-size: 60px;
    margin-bottom: 60px;
  }
  .faq-list-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
}
