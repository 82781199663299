.about-section {
  position: relative;
  h1 {
    font-family: "Bungee-inline";
    font-size: 60px;
  }
  .about-content {
    width: 80%;
    margin: auto;
    p{
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
  }
}
