.collection-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 70vh;
  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 20px;
    height: 100%;
  }
  img {
    width: 100%;
  }
  .collection-label {
    font-size: 30px;
    font-weight: 500;
  }
}
